// Taken from the Modal flow in Pay, requires at least 2 characters for TLDs.
const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,}$/i;

/**
 * Checks if the given string is a valid email address.
 *
 * @param {string} email The email to check.
 * @returns {boolean} `true` if the given string is a valid email address.
 */
export function isValidEmail(email?: string): boolean {
  if (!email) return false;
  return RegExp(EMAIL_REGEX).test(email);
}
