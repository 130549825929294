import {colors} from './colors';
import {
  AnimatedShopLogo,
  createAnimatedShopLogo,
  LargeSpinner,
  createLargeSpinner,
} from './svg';

const ELEMENT_CLASS_NAME = 'shop-status-indicator';

export enum StatusIndicatorLoader {
  Branded = 'branded',
  Regular = 'regular',
  Large = 'large',
}
export type StatusIndicatorType = 'loading' | 'success' | 'error';
export interface StatusIndicatorPayload {
  status: StatusIndicatorType;
  message?: string;
}

const ATTRIBUTE_LOADER = 'loader';

export class ShopStatusIndicator extends HTMLElement {
  #rootElement: ShadowRoot;
  #wrapperElement!: HTMLDivElement;
  #loader: StatusIndicatorLoader;

  constructor() {
    super();

    const template = document.createElement('template');
    template.innerHTML = getStyle();
    this.#rootElement = this.attachShadow({mode: 'open'});
    this.#rootElement.appendChild(template.content.cloneNode(true));

    this.#loader = StatusIndicatorLoader.Regular;
  }

  connectedCallback(): void {
    if (!this.#wrapperElement) {
      this.#wrapperElement = document.createElement('div');
      this.#wrapperElement.setAttribute('class', ELEMENT_CLASS_NAME);
      this.#rootElement.appendChild(this.#wrapperElement);
    }

    this.#loader =
      (this.getAttribute(ATTRIBUTE_LOADER) as StatusIndicatorLoader) ||
      StatusIndicatorLoader.Regular;
  }

  setStatus(payload: StatusIndicatorPayload): void {
    switch (payload.status) {
      case 'loading':
        this.#showLoading(payload.message);
        break;
      case 'success':
      case 'error':
        this.#showMessage(payload);
        break;
    }
  }

  #setContent(node: Node) {
    this.#wrapperElement.innerHTML = '';
    this.#wrapperElement.appendChild(node);
  }

  #setClassName(additionalClassName = '') {
    const className = [
      ELEMENT_CLASS_NAME,
      additionalClassName || `${ELEMENT_CLASS_NAME}-regular`,
    ].join(' ');

    this.#wrapperElement.setAttribute('class', className);
  }

  #showLoading(message?: string): void {
    const iconColor = this.#loader === 'branded' ? 'white' : 'brand';
    const animatedShopLogo: AnimatedShopLogo | LargeSpinner =
      this.#loader === 'large'
        ? createLargeSpinner()
        : createAnimatedShopLogo();
    animatedShopLogo.setAttribute('color', iconColor);
    if (message !== undefined) {
      animatedShopLogo.setAttribute('label', message);
    }

    const wrapperLoadingClass = `${ELEMENT_CLASS_NAME}-${this.#loader}`;

    this.#setContent(animatedShopLogo);
    this.#setClassName(wrapperLoadingClass);
  }

  #showMessage({status, message}: StatusIndicatorPayload): void {
    /**
     * We are using the same "processing_status_updated" event from Pay for both
     * the save discount request, and the sdk-confirm request. This made sense
     * when discount-saving happened as part of the sdk-confirm request. Now
     * that they're separate, we have a hack in Pay to not include the "discount saved"
     * message in the event payload for the sdk-confirm request status.
     *
     * This is a workaround, but makes it confusing to understand what's going on.
     * Just as we added a `discount_saved` event, we may also want to consider a
     * similar `confirm_completed` event.
     *
     * See https://github.com/Shopify/shop-identity/issues/1484.
     */
    if (message !== undefined) {
      const messageElement = document.createElement('span');
      messageElement.setAttribute('class', `${ELEMENT_CLASS_NAME}-message`);
      messageElement.textContent = message;
      this.#setContent(messageElement);
    }
    this.#setClassName(`${ELEMENT_CLASS_NAME}-${status}`);
  }
}

/**
 * @returns {string} element style
 */
function getStyle(): string {
  return `
    <style>
      .${ELEMENT_CLASS_NAME} {
        align-items: center;
        background-color: #F2F3F5;
        border-radius: 4px;
        color: #5433EB;
        display: flex;
        font-size: 14px;
        height: 28px;
        justify-content: center;
        padding: 11px;
      }

      .${ELEMENT_CLASS_NAME}-message {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
      }

      .${ELEMENT_CLASS_NAME}-error {
        background-color: ${colors.backgroundError};
        color: ${colors.error};
        text-align: left;
      }

      .${ELEMENT_CLASS_NAME}-branded {
        background-color: ${colors.brand};
      }

      .${ELEMENT_CLASS_NAME}-regular {
        background-color: ${colors.backgroundSubdued};
      }

      .${ELEMENT_CLASS_NAME}-large {
        background-color: transparent;
      }
    </style>
  `;
}

if (!customElements.get('shop-status-indicator')) {
  customElements.define('shop-status-indicator', ShopStatusIndicator);
}

/**
 * helper function which creates a new processing status element
 *
 * @param {StatusIndicatorLoader} loader the loader style to use
 * @returns {ShopStatusIndicator} a new StatusIndicator element
 */
export function createStatusIndicator(
  loader: StatusIndicatorLoader = StatusIndicatorLoader.Regular,
): ShopStatusIndicator {
  const element = document.createElement('shop-status-indicator');

  element.setAttribute('loader', loader);

  return element as ShopStatusIndicator;
}
