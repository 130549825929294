/**
 * @file Utility file which creates a singleton instance
 * used for communicating between components via a pub/sub mechanism
 */

export enum ShopHubTopic {
  UserStatusIdentity = 'userstatuschange:identity',
  UserStatusScope = 'userstatuschange:scope',
}

export interface ShopHubPayload {}

interface ShopHubToicEntry {
  publisherId: string;
  callback: (payload: ShopHubPayload) => void;
}
type ShopHubTopicEntries = {
  [key in ShopHubTopic]?: ShopHubToicEntry[];
};

export const ShopHub = (() => {
  class ShopSingleton {
    private _topics: ShopHubTopicEntries;

    constructor() {
      this._topics = {};
    }

    subscribe(
      topic: ShopHubTopic,
      publisherId: string,
      callback: (payload: ShopHubPayload) => void,
    ): void {
      this._topics[topic] = [
        ...(this._topics[topic] || []),
        {
          publisherId,
          callback,
        },
      ];
    }

    unsubscribe(topic: ShopHubTopic, publisherId: string): void {
      this._topics[topic] = (this._topics[topic] || []).filter(
        (entry) => entry.publisherId !== publisherId,
      );
    }

    unsubscribeAll(publisherId: string): void {
      Object.keys(this._topics).forEach((topic: string): void => {
        this.unsubscribe(topic as ShopHubTopic, publisherId);
      });
    }

    publish(topic: ShopHubTopic, publisherId: string, payload: ShopHubPayload) {
      this._topics[topic]?.forEach((entry) => {
        if (entry.publisherId === publisherId) return;

        entry.callback(payload);
      });
    }
  }

  let instance: ShopSingleton;

  return {
    getInstance: (): ShopSingleton => {
      if (!instance) {
        instance = new ShopSingleton();
      }

      return instance;
    },
  };
})();
