/**
 * @file Base class for components with access to the ShopHub singleton
 */
import {v4 as uuidv4} from 'uuid';

import WebComponent from './WebComponent';
import {ShopHub, ShopHubPayload, ShopHubTopic} from './utils';

export default abstract class ConnectedWebComponent extends WebComponent {
  private _shopHub = ShopHub.getInstance();
  private _publisherId: string = uuidv4();

  protected subscribeToHub(
    topic: ShopHubTopic,
    callback: (payload: ShopHubPayload) => void,
  ): void {
    this._shopHub.subscribe(topic, this._publisherId, callback);
  }

  protected unsubscribeAllFromHub(): void {
    this._shopHub.unsubscribeAll(this._publisherId);
  }

  protected unsubscribeFromHub(topic: ShopHubTopic): void {
    this._shopHub.unsubscribe(topic, this._publisherId);
  }

  protected publishToHub(
    topic: ShopHubTopic,
    payload: ShopHubPayload | undefined = {},
  ): void {
    this._shopHub.publish(topic, this._publisherId, payload);
  }
}
