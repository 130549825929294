import {colors} from '../colors';

export class ShopCloseIcon extends HTMLElement {
  constructor() {
    super();

    const template = document.createElement('template');
    const size = this.getAttribute('size') || '';
    template.innerHTML = getTemplateContents(size);

    this.attachShadow({mode: 'open'}).appendChild(
      template.content.cloneNode(true),
    );
  }
}

/**
 * @param {string} size the size of the icon
 * @returns {string} HTML content for the icon.
 */
function getTemplateContents(size: string) {
  return `
    <style>
      :host {
        display: flex;
      }

      svg path {
        fill: var(--icon-color, ${colors.close});
      }
    </style>
    <svg
        aria-hidden="true"
        width=${size}
        height=${size}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.4875 1.5877L14.9125 0.0126953L8.5 6.4252L2.0875 0.0126953L0.512497 1.5877L6.925 8.0002L0.512497 14.4127L2.0875 15.9877L8.5 9.5752L14.9125 15.9877L16.4875 14.4127L10.075 8.0002"
        />
    </svg>
  `;
}
