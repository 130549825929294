export const SMALL_SCREEN_WIDTH = 430;
const UNIFIED_LOGIN_COMPONENT_WIDTH = 320;

const SHEET_MODAL_STLYE = `
  bottom: 0;
  top: auto !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 32px 32px 0 0;
`;

export const SHEET_MODAL_HTML = `
<style>
.sda-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483647 !important;
  will-change: opacity, transform;
}

.sda-modal {
  background-color: #fff;
  min-width: ${UNIFIED_LOGIN_COMPONENT_WIDTH}px;
  border-radius: 32px;
  padding: 40px 28px 16px;
  will-change: opacity, transform;
}

.sda-modal:focus {
  outline: 0;
}

@media screen and (max-width: ${SMALL_SCREEN_WIDTH}px) {
  .sda-modal {
    position: absolute;
    ${SHEET_MODAL_STLYE}
    padding: 32px 0 16px;
    box-sizing: border-box;
  }

  .arrow {
    display: none;
  }
}

@media screen and (min-width: ${SMALL_SCREEN_WIDTH + 1}px) {
  .sda-overlay.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sda-modal.disable-popup {
  ${SHEET_MODAL_STLYE}
}

.sda-modal-close-button {
    background: none;
    border: none;
    position: absolute;
    right: 16px;
    top: 24px;
    padding: 8px;
    cursor: pointer;
    display: flex;
}

.sda-modal-close-button:focus,
.sda-modal-close-button:hover {
  border-radius: 6px;
  background: rgb(0 0 0 / 0.03);
  color: #7b61f0;
  outline: none;

  --icon-color: #7b61f0;
}

.sda-modal-close-button shop-close-icon {
    pointer-events: none;
}

.sda-landing {
    width: ${UNIFIED_LOGIN_COMPONENT_WIDTH}px;
    margin: auto;
}

.sda-shop-logo {
    text-align: center;
    padding: 12px 0 8px 0;
}

.sda-hidden {
  height: 0 !important;
  border: 0;
  padding: 0;
  margin: 0;
  visibility: hidden;
  overflow: hidden;
}

.focus-trap {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (forced-colors: active) {
  .sda-modal {
    border: 1px solid;
  }
}

.arrow {
  position: absolute;
  background-color: #fff;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}
</style>

<div class="sda-overlay sda-hidden">
  <button type="button" aria-hidden="true" class="focus-trap focus-trap--start"></button>
  <section class="sda-modal" part="modal" role="dialog" aria-modal="true" aria-label="Sign in with Shop" tabindex="-1">
      <button type="button" class="sda-modal-close-button" aria-label="Close">
          <shop-close-icon size="16"/>
      </button>
      <div class="sda-landing">
          <slot></slot>
      </div>
      <div class="sda-shop-logo">
          <shop-logo role="img" size="20" color="brand" label="Shop"/>
      </div>
  </section>
  <button type="button" aria-hidden="true" class="focus-trap focus-trap--end"></button>
</div>
`;
