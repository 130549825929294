/**
 * @file Lightweight Bugsnag Client to keep the bundle size small. The API should be compatible with the official
 * Bugsnag client, in order to be a drop-in replacement.
 */
import Client from './Client';
import {BreadcrumbType, BeforeNotifyCallback, ClientInitParams} from './types';

let client: Client | undefined;

const Bugsnag = {
  /**
   * Start Bugsnag. Must be called before any other Bugsnag methods.
   *
   * @param {ClientInitParams} params Initial setup params. This could include a list of `onError` callback
   * functions that are invoked with every notification, allowing to modify the event before it is sent to Bugsnag
   * or to prevent the event from being sent if any callback returns `false`.
   */
  start: (params: ClientInitParams) => {
    if (client) {
      // eslint-disable-next-line no-console
      console.warn('Bugsnag.start() has already been called. Ignoring.');
      return;
    }
    client = new Client(params);
  },

  /**
   * Add a breadcrumb to the stack. Breadcrumbs are sent to Bugsnag when an error is sent, providing additional
   * data on what happened prior to the error.
   *
   * @param {string} name The name of the breadcrumb.
   * @param {any} metaData Any additional data to send with the breadcrumb.
   * @param {BreadcrumbType} type The type of breadcrumb (i.e. "navigation" or "request"). See BreadcrumbType for options.
   */
  leaveBreadcrumb: (name: string, metaData: any, type: BreadcrumbType) => {
    if (!client) {
      // eslint-disable-next-line no-console
      console.warn('Bugsnag.leaveBreadcrumb() called before start().');
      return;
    }
    client.leaveBreadcrumb(name, metaData, type);
  },

  /**
   * Send a notification to Bugsnag.
   *
   * @param {Error} error The error to log.
   * @param {BeforeNotifyCallback} onBeforeNotify Optional callback allows modifying the event before it is sent to Bugsnag.
   * Use to set `severity` or add metadata with the `addMetadata` function call. If callback returns false, the event will
   * not be sent. Should behave similar to the `onError` callback of the official Bugsnag client:
   * https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
   */
  notify: (error: Error, onBeforeNotify?: BeforeNotifyCallback) => {
    if (!client) {
      // eslint-disable-next-line no-console
      console.warn('Bugsnag.notify() called before start().');
      return;
    }
    client.notify(error, onBeforeNotify);
  },
};

export default Bugsnag;
