import {PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT} from './utils/urls';

export default class MessageSender<T> {
  private readonly _eventDestination: HTMLIFrameElement | Window;
  private readonly _eventOrigins: string[];

  constructor(
    eventDestination: HTMLIFrameElement | Window,
    eventOrigins: string[],
  ) {
    this._eventDestination = eventDestination;
    this._eventOrigins = eventOrigins;
  }

  postMessage(message: T): void {
    // Note: `window instanceOf Window` does not work in certain browsers/Node.js environments,
    // so we check for `instanceOf HTMLIFrameElement` first.
    // See https://github.com/jsdom/jsdom/issues/2740
    const destination =
      this._eventDestination instanceof HTMLIFrameElement
        ? this._eventDestination.contentWindow
        : this._eventDestination;

    this._eventOrigins.forEach((origin) => {
      destination?.postMessage(message, origin);
    });
  }
}

export class PayMessageSender extends MessageSender<PayEvents> {
  constructor(eventDestination: HTMLIFrameElement | Window) {
    super(eventDestination, [PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT]);
  }
}

type PayEvents =
  | PrequalCreateEvent
  | PayEmailSubmittedEvent
  | PaySaveDiscountEvent
  | PayPhoneShareConsentReceivedEvent;

interface PrequalCreateEvent {
  type: 'createprequal';
  amount: number;
  currency: string;
  sellerId: number;
}

interface PayEmailSubmittedEvent {
  type: 'emailsubmitted';
  email: string;
  hideChange?: boolean;
}

interface PaySaveDiscountEvent {
  type: 'savediscount';
  discountCode: string;
}

interface PayPhoneShareConsentReceivedEvent {
  type: 'phoneshareconsentreceived';
  consented: boolean;
  skipDiscountSaving?: boolean;
}
