const SHOP_PAY_LOGO_ELEMENT = `
  <shop-pay-logo
    aria-hidden="true"
    role="img"
    size="large"
    background-color="#5a31f4"
  ></shop-pay-logo>
`;

export const SHOP_PAY_BUTTON_BASE_HTML = (showLogo?: boolean) => `
<style>
  * {
    box-sizing: border-box;
  }

  .shop-pay-button {
    background: #5a31f4 !important;
    line-height: normal;
    border-radius: var(--shop-pay-button-border-radius, 4px) !important;
    height: var(--shop-pay-button-height, 42px) !important;
    width: var(--shop-pay-button-width, 262px) !important;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    white-space: nowrap;
  }

  .shop-pay-button:not(.disabled):hover {
    background: #3c0def !important;
  }

  .shop-pay-button.disabled {
    opacity: 0.5;
  }

  .shop-pay-button.bordered {
    border: 1px solid #EDEDED;
  }

  shop-pay-logo {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
  }

  .visually-hidden {
    position: absolute;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    padding: 0;
  }
</style>

<a id="shop-pay-button-link" href="#" class="shop-pay-button" aria-label="Buy with Shop Pay">
  <span class="visually-hidden">Buy with Shop Pay</span>
  <slot id="pay-button-content">
  </slot>
  ${showLogo ? SHOP_PAY_LOGO_ELEMENT : ''}
</a>
`;
