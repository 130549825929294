import ErrorStackParser from 'error-stack-parser';
// eslint-disable-next-line import/no-extraneous-dependencies
import StackFrame from 'stackframe';

import {RELEASE_STAGES} from './constants';
import {BugsnagStackTraceFrame, ReleaseStage} from './types';

/**
 * Checks which environment shop-js is running in and returns the appropriate
 * release stage string. This corresponds to the Stage filter in the Bugsnag console.
 *
 * @typedef ReleaseStage
 * @returns {ReleaseStage} The release stage that corresponds to this environment.
 */
export function getReleaseStage(): ReleaseStage {
  // Gets set at build time by rollup-replace
  // eslint-disable-next-line no-process-env
  const nodeEnv = process.env.NODE_ENV;

  switch (nodeEnv) {
    case 'spin':
      return RELEASE_STAGES.spin;
    case 'staging':
      return RELEASE_STAGES.staging;
    case 'production':
      return RELEASE_STAGES.prod;
    case 'development':
    default:
      return RELEASE_STAGES.dev;
  }
}

/**
 * Parses the stack trace in a given error and returns the stack trace as expected by Bugsnag.
 *
 * @typedef BugsnagStackTraceFrame
 * @param {Error} error An error object.
 * @returns {BugsnagStackTraceFrame[]} A list of stack trace frame objects in the format expected by Bugsnag.
 */
export function parseStackTrace(error: Error): BugsnagStackTraceFrame[] {
  const stack = ErrorStackParser.parse(error);
  return stack.map((stackFrame: StackFrame) => ({
    method: stackFrame.functionName,
    file: stackFrame.fileName,
    lineNumber: stackFrame.lineNumber,
    columnNumber: stackFrame.columnNumber,
  }));
}

/**
 * Checks whether an error originated in shop-js.
 *
 * @param {Error} error An error object.
 * @returns {boolean} `true` if the given error came from shop-js, `false` otherwise.
 */
export function isShopJsError(error: Error): boolean {
  const stack = ErrorStackParser.parse(error);
  if (stack.length === 0) return false;
  return stack.some((frame) => frame.fileName?.includes('shop-js'));
}
