import Bugsnag from './bugsnag';

/**
 * Logs error message to console and sends to Bugsnag.
 *
 * @param {string} message The error message to log.
 */
export function logError(message: string) {
  // eslint-disable-next-line no-console
  console.error(`[shop-js] ${message}`);
  Bugsnag.notify(new Error(message));
}

/**
 * Logs information message to console and leaves a Bugsnag breadcrumb.
 *
 * @param {string} message The message to log.
 */
export function logInfo(message: string) {
  // eslint-disable-next-line no-console
  console.log(`[shop-js] ${message}`);
  Bugsnag.leaveBreadcrumb(message, {}, 'log');
}

/**
 * Logs a warning message to the developer and sends to Bugsnag as error.
 *
 * @param {string} message The message to log.
 */
export function logWarning(message: string) {
  // eslint-disable-next-line no-console
  console.warn(message);
  Bugsnag.notify(new Error(message), (event) => {
    event.severity = 'warning';
  });
}
