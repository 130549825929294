import {getHostName} from '../../common/utils';
import {logError} from '../../common/logging';

import {Cart, PaymentOption, Variant} from './types';

/**
 * @param {string} extractedHostname The extracted hostname of the store URL
 * @returns {string} The cart token of the provided hostname
 */
export async function getCart(extractedHostname: string): Promise<Cart> {
  try {
    const cartResponse = await fetch(`https://${extractedHostname}/cart.js`);
    const cartJson = await cartResponse.json();
    return cartJson;
  } catch (error) {
    logError(
      `Failed to fetch cart token for ${extractedHostname} due to error: ${error}`,
    );
    return {token: '', currency: '', items: []};
  }
}

interface ConstructLinkProps {
  /**
   * store url (e.g. `https://merchant.com` or `http://store.myshopify.com`).
   * Will validate that it's a valid URL and not a subset.
   */
  storeUrl: string;
  /**
   * A list of variants for which to construct the link.
   */
  variants: Variant[];
  /**
   * The payment option you'd like to be preselected upon landing on the Shop Pay checkout screen.
   */
  paymentOption?: PaymentOption | null;
  /**
   * The source from where the link was generated from.
   */
  source?: string | null;
  /**
   * A unique token that can be used to identify the source of the link.
   */
  sourceToken?: string | null;
  /**
   * The source from where the link was generated from.
   */
  redirectSource?: string | null;
  /**
   * The channel to attribute the order to.
   */
  channel?: string | null;
}
// eslint-disable-next-line jsdoc/require-jsdoc
export function constructLink({
  storeUrl,
  variants,
  paymentOption,
  source,
  sourceToken,
  redirectSource,
  channel,
}: ConstructLinkProps): string {
  const extractedHostname = getHostName(storeUrl);
  if (!extractedHostname || !variants.length) return '#';

  const variantsString = variants
    .map((variant) => {
      return `${variant.id}:${variant.quantity}`;
    })
    .join(',');
  const url = new URL(`https://${extractedHostname}/cart/${variantsString}`);
  const params = new URLSearchParams(url.search);

  params.append('payment', paymentOption || PaymentOption.ShopPay);
  if (source) {
    params.append('source', source);
  }
  if (sourceToken) {
    params.append('source_token', sourceToken);
  }

  if (redirectSource) {
    params.append('redirect_source', redirectSource);
  }

  if (channel) {
    params.append('channel', channel);
  }

  return `${url.href}?${params}`;
}

/**
 * @param {string} attributeString The string from the 'variants' attribute, formatted like "[id],[id]:[quantity]"
 * @returns {Variant[]} Variants and quantities from the parsed attributeString
 */
export function getVariantsFromAttributeString(
  attributeString: string,
): Variant[] {
  return attributeString.split(',').map((variant) => {
    const [id, stringQuantity] = variant.split(':');
    const numberQuantity = Number(stringQuantity ?? 1);
    const quantity = isNaN(numberQuantity) ? 1 : numberQuantity;
    return {id: Number(id), quantity};
  });
}

/**
 * @param {string} name The name of the cookie to get the value of
 * @returns {string} The value of the cookie with the provided name
 */
function getCookieValue(name: string): string | undefined {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');

    if (key === name) {
      return value;
    }
  }

  return undefined;
}

/**
 * @param {URL} transactionUrl The transaction url to redirect to buy_with_shop_pay with key appended
 * @param {PaymentOption} paymentOption The payment option you'd like to be preselected upon landing on the Shop Pay checkout screen.
 * @param {string} source The source from where the link was generated from.
 * @param {string} sourceToken A unique token that can be used to identify the source of the link.
 * @param {string} channel The channel to attribute the order to.
 * @returns {string} The full url used to redirect the url to checkout
 */
export function buildRedirectUrl(
  transactionUrl: URL,
  paymentOption: PaymentOption | null,
  source: string | null,
  sourceToken: string | null,
  channel: string | null,
): string {
  const params = new URLSearchParams();
  params.append('show_login', 'false');
  params.append('redirect_source', 'direct_checkout_cart');
  params.append(
    'pre_select_installments',
    String(paymentOption === PaymentOption.ShopPayInstallments),
  );

  const checkoutQueueToken = getCookieValue('_checkout_queue_token');
  const shopifySaCookie = getCookieValue('_shopify_sa_p');

  if (checkoutQueueToken !== undefined) {
    params.append('checkout_queue_token', checkoutQueueToken);
  }

  if (
    shopifySaCookie !== undefined &&
    decodeURIComponent(shopifySaCookie) === 'utm_source=shop_app'
  ) {
    params.append('referrer', 'shop_app');
  }

  if (source !== null) {
    params.append('source', source);
  }

  if (sourceToken !== null) {
    params.append('source_token', sourceToken);
  }

  if (channel !== null) {
    params.append('channel', channel);
  }

  return `${transactionUrl.href}&${params}`;
}
