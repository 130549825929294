import {getDuration} from '../utils/utils';

import {getReleaseStage, isShopJsError} from './utils';
import {BUGSNAG_NOTIFIER, RELEASE_STAGES} from './constants';
import {
  Breadcrumb,
  BreadcrumbType,
  BugsnagEventType,
  BugsnagRequestBody,
  ClientInitParams,
  BeforeNotifyCallback,
  ReleaseStage,
} from './types';
import BugsnagEvent from './BugsnagEvent';

export default class Client {
  private readonly _apiKey: string;
  private readonly _appId: string;
  private readonly _appVersion: string;
  private readonly _metadata: any;
  private readonly _onError: BeforeNotifyCallback[] = [];
  private readonly _releaseStage: ReleaseStage;
  private readonly _startTime: Date;
  private _breadcrumbs: Breadcrumb[] = [];

  constructor({
    apiKey,
    appId,
    appVersion,
    metadata,
    onError,
  }: ClientInitParams) {
    this._apiKey = apiKey;
    this._appId = appId;
    this._appVersion = appVersion;
    this._metadata = metadata;
    this._onError = onError || [];
    this._startTime = new Date();
    this._releaseStage = getReleaseStage();
    this._initWindowErrorHandler();
    this.leaveBreadcrumb('Bugsnag started', {}, 'state');
  }

  // eslint-disable-next-line no-warning-comments
  // TODO: Capture console.log output, Network requests
  leaveBreadcrumb(name: string, metaData: any, type: BreadcrumbType) {
    this._breadcrumbs.push({
      name,
      metaData,
      type,
      timestamp: new Date().toISOString(),
    });
  }

  notify(error: Error, onBeforeNotify?: BeforeNotifyCallback) {
    if (this._releaseStage === RELEASE_STAGES.dev) {
      this._logToConsole(error);
      return;
    }

    this._sendToBugsnag(error, onBeforeNotify);
  }

  private _initWindowErrorHandler() {
    window.addEventListener('error', (event) => {
      const {error} = event;
      if (error && isShopJsError(error)) {
        this.notify(error);
      }
    });
  }

  private _logToConsole(error: Error) {
    // eslint-disable-next-line no-console
    console.error(`[bugsnag - shop-js] error in ${this._releaseStage}`, {
      error,
    });
  }

  private _sendToBugsnag(error: Error, onBeforeNotify?: BeforeNotifyCallback) {
    const now = new Date();
    const time = now.toISOString();
    const duration = getDuration(this._startTime, now);
    const {_breadcrumbs, _appId, _appVersion, _releaseStage, _metadata} = this;

    const event: BugsnagEventType = new BugsnagEvent({
      error,
      breadcrumbs: _breadcrumbs,
      appId: _appId,
      appVersion: _appVersion,
      releaseStage: _releaseStage,
      duration,
      time,
      metaData: {..._metadata},
    });

    // Combine global callback with the one passed to notify()
    const callbacks: BeforeNotifyCallback[] = [
      ...this._onError,
      ...(onBeforeNotify ? [onBeforeNotify] : []),
    ];

    if (callbacks) {
      const shouldNotNotify = callbacks.some((fn) => fn(event) === false);
      if (shouldNotNotify) return;
    }

    const request = new XMLHttpRequest();

    request.open('POST', 'https://notify.bugsnag.com/');

    request.setRequestHeader('Content-Type', 'application/json');
    request.setRequestHeader('Bugsnag-Api-Key', this._apiKey);
    request.setRequestHeader('Bugsnag-Payload-Version', '4');
    request.setRequestHeader('Bugsnag-Sent-At', time);

    const body: BugsnagRequestBody = {
      apiKey: this._apiKey,
      notifier: BUGSNAG_NOTIFIER,
      events: [event],
    };

    request.send(JSON.stringify(body));
  }
}
