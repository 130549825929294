export const AUTHORIZE_POPUP_WIDTH = 432;
export const AUTHORIZE_POPUP_HEIGHT = 600;

const HEIGHT = '0';
const WIDTH = '100%';

export const LOAD_TIMEOUT_MS = 10000;
export const EMAIL_LISTENER_DEBOUNCE_MS = 200;

export const SHOP_LOGIN_BUTTON_HTML = `<style>:host{display:inline-block;line-height:normal;}iframe{border:none;height:${HEIGHT};width:${WIDTH};}</style><iframe></iframe>`;
export const SHOP_LOGIN_DEFAULT_STYLE = `<style>
  iframe {
    border:none;
    height:${HEIGHT};
    width:${WIDTH};
  }
  </style>`;

export const ERRORS = {
  temporarilyUnavailable: {
    code: 'temporarily_unavailable',
    message: 'Shop login is temporarily unavailable',
  },
};

export const ATTRIBUTE_CLIENT_ID = 'client-id';
export const ATTRIBUTE_VERSION = 'version';
export const ATTRIBUTE_ACTION = 'action';
export const ATTRIBUTE_STOREFRONT_ORIGIN = 'storefront-origin';
export const ATTRIBUTE_KEEP_MODAL_OPEN = 'keep-modal-open';

// Only respond with email-verified users. Users that aren't email verified will be treated as if they do not exist.
export const ATTRIBUTE_EMAIL_VERIFICATION_REQUIRED =
  'email-verification-required';

// Do not render the "Contine with Shop" button. Used if you only want to use the input listener or requestShow function.
export const ATTRIBUTE_HIDE_BUTTON = 'hide-button';

// Prevent Pay from showing the sign up flow
export const ATTRIBUTE_DISABLE_SIGN_UP = 'disable-sign-up';

// Redirect to this URI after successful Pay authentication
export const ATTRIBUTE_REDIRECT_URI = 'redirect-uri';

// Attribute to indicate which redirect type to use (e.g. 'top_frame' or 'pop_up')
export const ATTRIBUTE_REDIRECT_TYPE = 'redirect-type';

// Automatically open the modal if an active Pay user cookie is detected
export const ATTRIBUTE_AUTO_OPEN = 'auto-open';

// String to define in which context shop-login-button is used. Each distinct feature should pass a unique analytics context string.
export const ATTRIBUTE_ANALYTICS_CONTEXT = 'analytics-context';

// Analytics trace ID to override the default generated trace ID
export const ATTRIBUTE_ANALYTICS_TRACE_ID = 'analytics-trace-id';

// Attribute to indicate that you want to use the OAuth code flow (if value is 'code')
export const ATTRIBUTE_RESPONSE_TYPE = 'response-type';

// Attribute to indicate what response-mode you want (e.g, 'query', 'web_message')
export const ATTRIBUTE_RESPONSE_MODE = 'response-mode';

// Attribute for PKCE code challenge in an OAuth code flow
export const ATTRIBUTE_CODE_CHALLENGE = 'code-challenge';

// Attribute for PKCE code challenge method in an OAuth code flow
export const ATTRIBUTE_CODE_CHALLENGE_METHOD = 'code-challenge-method';

// Attribute for state in an OAuth code flow
export const ATTRIBUTE_STATE = 'state';

// Attribute for scope in an OAuth code flow
export const ATTRIBUTE_SCOPE = 'scope';

// Attribute for requesting the pay alternate domain to enable features such as passkeys.
export const ATTRIBUTE_USE_PAY_ALT_DOMAIN = 'use-pay-alt-domain';

// Attribute to indicate what Sign In With Shop flow to use (e.g., 'prequal', 'default')
export const ATTRIBUTE_FLOW = 'flow';

// Attribute to indicate the version of the Sign In With Shop flow (e.g., 'sign_in', 'sign_up')
export const ATTRIBUTE_FLOW_VERSION = 'flow-version';

// Attribute for email login_hint
export const ATTRIBUTE_EMAIL = 'email';

// Attribute for modal anchor
export const ATTRIBUTE_ANCHOR_SELECTOR = 'anchor-to';

// Attribute to enable developer mode
export const ATTRIBUTE_DEV_MODE = 'dev-mode';

// Attribute to customize the modal title
export const ATTRIBUTE_MODAL_TITLE = 'modal-title';

// Attribute to customize the modal description
export const ATTRIBUTE_MODAL_DESCRIPTION = 'modal-description';

// Attribute to customize the modal logo src
export const ATTRIBUTE_MODAL_LOGO_SRC = 'modal-logo-src';

// Attribute for API key of the app
export const ATTRIBUTE_API_KEY = 'api-key';

export const ATTRIBUTE_POP_UP_NAME = 'pop-up-name';

export const ATTRIBUTE_POP_UP_FEATURES = 'pop-up-features';

export const ATTRIBUTE_MODAL_BRAND = 'modal-brand';

// Enables the personalization consent feature for a specific use-case
export const ATTRIBUTE_CONSENT_CHALLENGE = 'consent-challenge';

export const ATTRIBUTE_CHECKOUT_VERSION = 'checkout-version';

export const ATTRIBUTE_SHOP_ID = 'shop-id';

// Attributes to provide the customer's first & last name.
// Note: If Shop derives a name from the Shop account's billing address, that will be preferred over these values.
export const ATTRIBUTE_FIRST_NAME = 'first-name';
export const ATTRIBUTE_LAST_NAME = 'last-name';
