import {SHOPIFY_S_COOKIE} from '../constants';
import {getCookie} from '../utils/cookies';

import {
  Breadcrumb,
  BugsnagEventType,
  BugsnagEventUser,
  BugsnagException,
  BugsnagSeverity,
  BuildEventParams,
} from './types';
import {parseStackTrace} from './utils';

/**
 * An event object that is accepted by Bugsnag.
 *
 * @typedef BugsnagEvent
 * @typedef BugsnagStackTraceFrame
 * @typedef Breadcrumb
 * @typedef ReleaseStage
 * @typedef OrientationType
 * @typedef BugsnagEventUser
 * @param {Object} root The root parameter object.
 * @param {string} root.error The error to be logged to Bugsnag.
 * @param {Breadcrumb[]} root.breadcrumbs A log of events that occurred prior to the error.
 * @param {string} root.appId The application id, to log as additional metadata.
 * @param {string} root.appVersion The application version, to log as additional metadata.
 * @param {ReleaseStage} root.releaseStage The environment the application is running in (i.e. development/production).
 * @param {number} root.duration The length of time (in ms) the application has been running.
 * @param {string} root.time The time at which the event has occurred, in ISO format.
 * @param {Object} root.metaData Any additional information that should be logged with the event.
 * @returns {BugsnagEventType} The event object that is accepted by Bugsnag.
 */
export default class BugsnagEvent implements BugsnagEventType {
  payloadVersion: '4' = '4';
  exceptions: BugsnagException[] = [];
  severity: BugsnagSeverity;
  unhandled = false;
  severityReason: BugsnagEventType['severityReason'] = {
    type: 'handledException',
  };

  app: BugsnagEventType['app'];
  device: BugsnagEventType['device'];
  request: BugsnagEventType['request'];
  breadcrumbs: Breadcrumb[];
  context: string;
  metaData: {[key: string]: any} = {};
  user: BugsnagEventUser;
  session = {};

  constructor({
    error,
    severity,
    breadcrumbs,
    appId,
    appVersion,
    releaseStage,
    duration,
    time,
    metaData,
  }: BuildEventParams) {
    this.exceptions = [
      {
        errorClass: error.name,
        errorMessage: error.message,
        type: 'browserjs',
        stacktrace: parseStackTrace(error),
        message: error.message,
      },
    ];
    this.severity = severity || 'error';
    this.app = {
      id: appId,
      version: appVersion,
      releaseStage,
      type: 'browser',
      duration,
    };
    this.device = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      locale: navigator.userLanguage || navigator.language,
      userAgent: navigator.userAgent,
      time,
      orientation: window.screen?.orientation?.type,
    };
    this.request = {
      url: window.location.href,
    };
    this.breadcrumbs = breadcrumbs;
    this.context = window.location.pathname;
    this.metaData = metaData;
    this.user = {
      shopifyS: getCookie(SHOPIFY_S_COOKIE),
    };
  }

  addMetadata(name: string, metaData: any) {
    this.metaData[name] = metaData;
  }
}
